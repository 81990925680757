import React from "react"
import Box from "../Box";
import FilteredList from "../search/FilteredList";
import Select from "../Select";



export default function SearchFilters(props) {

  function handleOrderSort(name, value) {
    props.handleFilterChange(value, 'order_by')
  }

  return(
    <React.Fragment>
      <Box mb={2} width={1}>
        <Select 
          name='order_by'
          label='Sort By'
          value={props.filterValues.order_by}
          handleChange={handleOrderSort}
          entities={[
            {name: 'Relevance', value: '_score'},
            {name: 'Date', value: 'effective_date_str'}
          ]}
        />
      </Box>

      <Box width={1} height={1} overflow='auto'>
        {props.companies ? 
          <Box width={1}>
            <FilteredList
              key={props.companies.map(company => `${company.name}-${company.count}`).join('')} 
              label='Companies'
              items={props.companies}
              filterValues={props.filterValues}
              filterName='companies'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box> 
        : ""}

        {/* <Box mt={2}>
          <YearRangeSlider 
            key={props.years.map(year => year.name).join('')} 
            years={props.years}
            originalYears={props.originalYears}
            beginYear={props.filterValues.begin_year}
            endYear={props.filterValues.end_year}
            handleFilterChange={props.handleFilterChange}
          />
        </Box> */}

        {props.patentDataKinds ? <Box mt={3} width={1}>
          <FilteredList
            key={props.patentDataKinds.map(patentDataKind => patentDataKind.value).join('')} 
            label='Patent Type'
            items={props.patentDataKinds}
            filterValues={props.filterValues}
            filterName='patent_data_kinds'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> : ""}

        {props.classifications ? <Box mt={3} width={1}>
          <FilteredList
            key={props.classifications.map(classification => classification.value).join('')} 
            label='Classification'
            items={props.classifications}
            filterValues={props.filterValues}
            filterName='classifications'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> : ""}
      </Box>
    </React.Fragment>
  )
}